import React from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Card from "./Card"

/** Locales */
import locales from "./locales/Card.json"

const Advantages = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  return (
    <Flex
      id="pricing"
      direction="column"
      alignItems="center"
      mt={device === "desktop" ? "13.888888889vw" : "50.241545894vw"}
      mb={device === "desktop" ? "11.111111111vw" : "23.429951691vw"}
    >
      <Container
        direction="column"
        delimiter={device === "desktop" ? "54.027777778vw" : "80.6763vw"}
        mx="auto"
      >
        <Title
          fontSize={device === "desktop" ? "4em" : "9.6618vw"}
          fontFamily="Raleway"
          weight="black"
          textAlign={device === "desktop" && "center"}
        >
          {locales[lang]["TITLE"]}
        </Title>
        <Text
          fontSize={device === "desktop" ? "1.111111111vw" : "3.8647vw"}
          mt={device === "desktop" ? "2.2222vw" : "5.7971vw"}
          mb={device === "desktop" ? "3.888888889vw" : "7.7295vw"}
        >
          {locales[lang]["DESCRIPTION"]}
        </Text>
      </Container>
      <CardGrid device={device} justifyContent="center">
        {locales[lang]["ITEMS"].map(item => (
          <Card
            key={item.title}
            icon={item.icon}
            description={item.description}
          />
        ))}
      </CardGrid>
    </Flex>
  )
}

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ device }) =>
    device === "desktop" ? "repeat(4, 1fr)" : "1fr"};
  gap: ${({ device }) => (device === "desktop" ? "4.1667vw" : "5.7971vw")};
`

export default Advantages
