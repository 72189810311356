import React from "react"

/** Auxiliaries */
import { Flex, Icon, Text } from "../../auxiliaries"

import { useDeviceType } from "../../../utils/layout/LayoutContext"
import styled from "styled-components"

const getIconHeight = device =>
  device === "desktop" ? "6.944444444vw" : "19.323671498vw"

const Card = ({ title, icon, description }) => {
  const device = useDeviceType()

  if (!device) return null

  return (
    <Wrapper device={device} direction="column" justifyContent="space-between">
      <Flex justifyContent="center">
        <Icon
          name={icon}
          height={getIconHeight(device)}
          width={getIconHeight(device)}
        />
      </Flex>
      <Text
        maxWidth={device === "desktop" ? "24.201388889vw" : "100%"}
        fontSize={device === "desktop" ? "1.1111vw" : "3.8647vw"}
        marginTop={"20px"}
      >
        {description}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  padding: 20px;
`

export default Card
