import React, { useState } from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Flex, Icon, Text, Title } from "../../auxiliaries"

/** Utils */
import { CLEAR_100, DARK_900 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Slider from "../Slider"

/** Locales */
import locales from "./locales/Benefits.json"

const Benefits = () => {
  const device = useDeviceType()
  const lang = useLang()


  const [active, setActive] = useState(
    Math.floor(locales[lang]["ITEMS"].length / 2)
  )

  if (!device) return null

  const onActiveChange = (item, index) => {
    setActive(index)
  }

  return (
    <Flex
      id="advantages"
      direction="column"
      gap={device === "desktop" ? "5.694444444vw" : ""}
    >
      <Flex
        direction={device === "desktop" ? "row" : "column"}
        justifyContent={device === "desktop" && "space-between"}
        alignItems={device === "desktop" && "center"}
        gap={device === "desktop" ? "4.722222222vw" : ""}
        width={device === "desktop" ? "80.555555556vw" : "80.6763vw"}
        ml={device === "desktop" ? "13.888888889vw" : "9.6618vw"}
      >
        <Flex
          direction="column"
          gap={device === "desktop" ? "2.2222vw" : "5.7971vw"}
        >
          <Title
            maxWidth={device === "desktop" ? "24.201388889vw" : "100%"}
            fontSize={device === "desktop" ? "4em" : "9.6618vw"}
            fontFamily="Raleway"
            weight="black"
          >
            {locales[lang]["ITEMS"][active].title}
          </Title>
          <Text
            maxWidth={device === "desktop" ? "24.201388889vw" : "100%"}
            fontSize={device === "desktop" ? "1.1111vw" : "3.8647vw"}
          >
            {locales[lang]["ITEMS"][active].description}
          </Text>
        </Flex>
        <Icon
          name={locales[lang]["ITEMS"][active].icon}
          height={device === "desktop" ? "24.7639vw" : "56.038647343vw"}
          width={device === "desktop" ? "51.631944444vw" : "80.6763vw"}
        />
      </Flex>
      <SliderWrapper
        device={device}
        justifyContent="center"
        height={device === "desktop" ? "9.166677778vw" : "19.323671498vw"}
        mt={device === "mobile" && "9.6618vw"}
      >
        <Slider
          onItemClick={onActiveChange}
          items={locales[lang]["ITEMS"]}
          component={({ active, label }) => (
            <Text
              key={label}
              color={active ? DARK_900 : CLEAR_100}
              fontSize={device === "desktop" ? "1.3889vw" : "3.8647vw"}
              fontFamily="Raleway"
              weight="black"
              width="max-content"
            >
              {label}
            </Text>
          )}
          activePadding={{
            px: device === "desktop" ? "30px" : "9.6618vw",
            py: device === "desktop" ? "30px" : "7.729468599vw",
          }}
          padding={{
            px: device === "desktop" ? "20px" : "9.6618vw",
            py: device === "desktop" ? "20px" : "7.729468599vw",
          }}
          emptyItemWidth="40vw"
        />
      </SliderWrapper>
    </Flex>
  )
}

const SliderWrapper = styled(Flex)`
  position: ${({ device }) => device === "mobile" && "relative"};
  overflow-x: ${({ device }) => device === "mobile" && "hidden"};

  & > div {
    position: ${({ device }) => device === "mobile" && "absolute"};
  }
`

export default Benefits
