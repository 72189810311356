import React from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Button, Flex, Link, Text } from "../../auxiliaries"

/** Utils */
import {
  CLEAR_100,
  GRAY_650,
  PURPLE_500,
  YELLOW_100,
  YELLOW_500,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"

import { CgServer } from "react-icons/cg"

/** Locales */
import locales from "./locales/Card.json"
import { navigate } from "gatsby"

const InfoTag = ({ device, title, description }) => (
  <Flex direction="column" gap={device === "desktop" ? "0.138888889vw" : "0vw"}>
    <Text
      fontSize={device === "desktop" ? "1.3889vw" : "5.7971vw"}
      weight="black"
    >
      {title}
    </Text>
    <Text
      color={GRAY_650}
      fontSize={device === "desktop" ? "0.833333333vw" : "2.8986vw"}
    >
      {description}
    </Text>
  </Flex>
)

const Card = ({ lang = "PT" }) => {
  const device = useDeviceType()

  if (!device) return null

  return (
    <Flex
      direction={device === "desktop" ? "row" : "column"}
      alignItems="center"
      justifyContent={device === "desktop" ? "space-between" : "flex-start"}
      gap={device === "desktop" ? "80px" : "8.6957vw"}
      bgColor={YELLOW_100}
      px={device === "desktop" ? "4.166666667vw" : "9.6618vw"}
      py={device === "desktop" ? "2.222222222vw" : "7.729468599vw"}
    >
      <Flex
        alignItems="center"
        gap={device === "desktop" ? "0.833333333vw" : "3.8647vw"}
      >
        <Text
          color={YELLOW_500}
          fontSize={device === "desktop" ? "6.944444444vw" : "19.323671498vw"}
          weight="black"
        >
          <Flex alignItems="center">
            <CgServer />
          </Flex>
        </Text>
        <Flex direction="column" mb={device === "desktop" ? "0vw" : "1vw"}>
          <Text
            fontSize={device === "desktop" ? "1.388888889vw" : "5.7971vw"}
            fontFamily="Raleway"
            weight="black"
          >
            {locales[lang]["NAME"]}
          </Text>
        </Flex>
      </Flex>
      <Info
        device={device}
        display="flex"
        direction={device === "desktop" ? "row" : "column"}
        alignItems={"center"}
        justifyContent="space-between"
        flexWrap="wrap"
        gap={device === "desktop" ? "2.777777778vw" : ""}
        width={device === "mobile" ? "100%" : ""}
      >
        <InfoTag
          device={device}
          title={"1U a 44U"}
          description={locales[lang]["SPACES"]}
        />
        <InfoTag
          device={device}
          title={"1GBps a 10GBps"}
          description={locales[lang]["NETWORKS"]}
        />
        <InfoTag device={device} title={"99.9%"} description={"Uptime SLA"} />
        <InfoTag
          device={device}
          title={"São Paulo"}
          description={locales[lang]["DATACENTER"]}
        />
      </Info>
      <Flex mt={device === "desktop" ? "0.833333333vw" : "0vw"}>
        <Link href="https://wa.me/5561991196052/?text=Vim+pela+EnxHost!">
          <Button
            bgColor={PURPLE_500}
            px={device === "mobile" && "5.7971vw"}
            py={device === "mobile" && "4.347826087vw"}
          >
            <Text
              fontFamily="Raleway"
              fontSize={device === "mobile" ? "3.8647vw" : "16px"}
              weight="bold"
              color={CLEAR_100}
            >
              {locales[lang]["SIGN_PLAN"]}
            </Text>
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}

const Info = styled(Flex)`
  padding-bottom: ${({ device }) => (device === "desktop" ? "1.3889vw" : "")};
  border-bottom: 1px solid ${YELLOW_500};
`

export default Card
